<template>
  <div>
    <div class="linkpoint">
      <div class="text-con w1120">
        <h2>灵点 LinkPoint 数字孪生设计引擎</h2>
        <p class="textarea">
          自研数字孪生设计引擎，高效赋能团队协同开发，为终端客户提供高质量数字孪生应用方案
        </p>
        <mt-button
          :text="`立即试用`"
          radius="8"
          @click.native="toWorkSpace"
        ></mt-button>
      </div>
    </div>
    <Tab1></Tab1>
    <Tab2></Tab2>
  </div>
</template>

<script>
import CustomLinkTab from "../custom-linkTab/index.vue";
import Tab1 from "./tab1/index.vue";
import Tab2 from "./tab2/index.vue";
export default {
  components: { CustomLinkTab, Tab1, Tab2 },
  data() {
    return {
      list: ["产品优势", "应用场景"],
      selectIndex: 0,
      componentMap: {
        0: Tab1,
        1: Tab2,
      },
    };
  },
  methods: {
    toWorkSpace() {
      location.href = location.origin + "/workspace/";
    },
  },
};
</script>

<style lang="scss" scoped>
.linkpoint {
  width: 100%;
  margin-top: 60px;
  height: calc(100vh - 60px);
  background-image: url("../../../../../assets/product/linkPonit-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  .text-con {
    h2 {
      font-weight: 700;
      font-size: 64px;
      line-height: 56px;
    }
    .textarea {
      height: 134px;
      width: 100%;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 22px;
      line-height: 44px;
      margin-top: 46px;
    }
  }
}
</style>
