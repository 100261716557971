<template>
  <div class="backColor">
    <div class="tab2 w1120">
      <h2>应用场景</h2>
      <div class="content">
        <div class="top">
          <div
            class="item"
            :class="selectIndex === index ? 'select-item' : ''"
            v-for="(item, index) in list"
            @click="changeSelectIndex(index)"
          >
            {{ item }}
          </div>
          <div class="item-line"></div>
          <div
            class="bottom"
            :class="selectIndex === 0 ? 'select-one' : 'select-two'"
          ></div>
        </div>
        <component :is="componentMap[selectIndex]"></component>
      </div>
    </div>
  </div>
</template>
<script>
import Personage from "./personage/index.vue";
import MultiUser from "./multi-user/index.vue";
export default {
  data() {
    return {
      selectIndex: 0,
      list: ["个人用户", "多人协作"],
      componentMap: {
        0: "Personage",
        1: "MultiUser",
      },
    };
  },
  methods: {
    changeSelectIndex(index) {
      this.selectIndex = index;
    },
  },
  components: { Personage, MultiUser },
};
</script>

<style lang="scss" scoped>
.tab2 {
  height: 928px;
  padding-top: 62px;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #333439;
    text-align: center;
  }
  .content {
    margin-top: 50px;
    width: 1120px;
    background-color: #ffffff;
    .top {
      width: 100%;
      height: 60px;
      display: flex;
      position: relative;
      .item {
        cursor: pointer;
        width: 560px;
        height: 100%;
        border-bottom: 1px solid #ebebeb;
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        color: #333439;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .select-item {
        color: #357aff;
      }
      .item-line {
        position: relative;
        left: -559px;
        width: 1px;
        height: 60px;
        background: #ebebeb;
      }
      .bottom {
        width: 560px;
        height: 2px;
        background-color: #357aff;
        position: absolute;
        top: 58px;
        transition: all 0.3s;
      }
      .select-one {
        left: 0;
      }
      .select-two {
        left: 560px;
      }
    }
  }
}
</style>
