<template>
  <div class="personage">
    <div class="left">
      <div class="icon icon-bot">
        <img src="@/assets/product/pic-tops.png" alt="" />
      </div>
      <div class="icon icon-top">
        <img src="@/assets/product/pic-bots.png" alt="" />
      </div>
    </div>
    <div class="right">
      <h2>团队用户</h2>
      <p>使用灵点编辑器共享场景文件</p>
      <div class="button">
        <mt-button
          :text="`立即试用`"
          width="137"
          height="36"
          radius="8"
          fontSize="14"
          @click.native="toWorkSpace"
        ></mt-button>
      </div>
      <div class="line"></div>
      <h2>能够提供</h2>
      <p>
       通过Link-Point引擎入口，多人协同开发，工作流<br/>实时显示，提高效率、激发团队活力。
      </p>
      <div class="line"></div>
      <h2>推荐搭配使用</h2>
      <div class="other">
        <div class="pictuer" @click="$router.push('/productlinkMior')">
          灵思
        </div>
        <div class="pictuer" @click="$router.push('/productlinkShow')">
          灵秀
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toWorkSpace() {
      location.href = location.origin + "/workspace/";

    },
  },
};
</script>

<style lang="scss" scoped>
.personage {
  width: 100%;
  display: flex;
  .left {
    width: 740px;
    .icon {
      width: 479px;
      height: 268px;
      position: relative;
      filter: drop-shadow(4px 5px 6px rgba(0, 0, 0, 0.1));
    }
    .icon-bot {
      top: 37px;
      left: 33px;
    }
    .icon-top {
      top: -20px;
      left: 220px;
    }
  }
  .right {
    background-color: #fafafa;
    width: 380px;
    height: 567px;
    padding-left: 32px;
    padding-top: 24px;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 20px;
      color: #333439;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #707174;
      margin-bottom: 32px;
    }
    .line {
      width: 316px;
      height: 1px;
      background: #ebebeb;
      margin-top: 45px;
      margin-bottom: 34px;
    }
    .other {
      display: flex;
      margin-bottom: 36px;

      .pictuer {
        font-weight: 500;
        font-size: 14px;
        margin-right: 32px;
        width: 50px;
        height: 56px;
        display: flex;
        color: #333439;
        align-items: center;
        &:hover {
          cursor: pointer;
          color: #357aff;
        }
      }
    }
  }
}
</style>
